import BackToHomeButton from "../components/BackToHomeButton";
import DownloadPdfButton from "../components/DownloadPdfButton";
import Footer from "../components/Footer";
import MenuCarousel from "../components/MenuCarousel";
import NavBar from "../components/NavBar";
import OrderNowButton from "../components/OrderNowButton";
import HandIcon from "../components/icons/HandIcon";
import LogoIcon from "../components/icons/LogoIcon";

export default function MenuPage() {
  return (
    <div className="overflow-hidden">
      <NavBar showImmediately={true} />

      <div className="md:container md:mx-auto mt-[92px] md:mb-8 mx-4 ">
        <div className="md:min-h-[50vh] flex-col py-16 justify-center border-legno border-t-2 flex gap-8  md:py-6 items-center">
          <HandIcon />
          <div className="text-center gap-4 flex flex-col">
            <h1 className="font-awesome text-5xl font-bold">
              Oh No! Lost in the sauce?
            </h1>
            <h2 className="font-neuething-regular text-xl">
              It seems like the page you’re looking for is taking a pizza break.
            </h2>
          </div>
          <BackToHomeButton />
        </div>
      </div>

      <Footer />
    </div>
  );
}
