import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import AccordionOpenIcon from "../components/icons/AccordionOpenIcon";
import config from "../config"; // Import your config or API URL here
import AccordionCloseIcon from "../components/icons/AccordionCloseIcon";
import { Helmet } from "react-helmet";

const fetchFaqs = async () => {
  const reqOptions = {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
    },
  };

  try {
    const response = await fetch(`${config.api}/api/faqs`, reqOptions);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data.data; // Adjust based on your API response
  } catch (error) {
    console.error("Failed to fetch FAQs:", error);
    throw error;
  }
};

// Helper function to convert Answer object to plain text
const extractAnswerText = (answer) => {
  return answer
    .map((part) => part.children.map((child) => child.text).join(""))
    .join("\n"); // Assuming answers are separated by new lines
};

export default function FaqsPage() {
  const [faqs, setFaqs] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getFaqs = async () => {
      try {
        const faqsData = await fetchFaqs();
        if (Array.isArray(faqsData)) {
          const formattedFaqs = faqsData.map((faq) => ({
            question: faq.attributes.Question,
            answer: extractAnswerText(faq.attributes.Answer),
            isOpen: false,
            isHovered: false,
          }));
          setFaqs(formattedFaqs);
        } else {
          setError("Received data is not an array");
        }
      } catch (err) {
        setError(err.message);
      }
    };

    getFaqs();
  }, []);

  const toggleContent = (index) => {
    setFaqs((prevFaqs) =>
      prevFaqs.map((faq, i) =>
        i === index ? { ...faq, isOpen: !faq.isOpen } : faq
      )
    );
  };

  const handleMouseEnter = (index) => {
    setFaqs((prevFaqs) =>
      prevFaqs.map((faq, i) =>
        i === index ? { ...faq, isHovered: true } : { ...faq, isHovered: false }
      )
    );
  };

  const handleMouseLeave = (index) => {
    setFaqs((prevFaqs) =>
      prevFaqs.map((faq, i) => ({ ...faq, isHovered: false }))
    );
  };

  const contentVariants = {
    open: { height: "auto", opacity: 1, transition: { duration: 0.3 } },
    closed: { height: 0, opacity: 0, transition: { duration: 0.3 } },
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="overflow-x-hidden">
      <Helmet>
        <title>FAQs | Marc's Artisanal Pizzeria Miami Beach</title>
        <meta
          name="description"
          content="Find answers to frequently asked questions about Marc's Artisanal Pizzeria. Learn about our ingredients, ordering process, and more."
        />
        <link rel="canonical" href="https://marcsartisanpizzeria.com/faqs" />
      </Helmet>
      <NavBar showImmediately={true} />
      <div className="md:container md:mx-auto mt-[92px] md:mb-8 mx-4 ">
        <div className="flex flex-col gap-2 py-6 border-t-2 border-b-2 md:flex-row md:justify-between border-legno md:items-center">
          <div className="flex flex-col gap-2">
            <h1
              className="mx-4 text-5xl font-bold md:text-6xl font-awesome md:mx-0"
              data-aos="fade-in"
            >
              FAQs
            </h1>
            <h2
              className="mx-4 text-xl italic font-awesome-italic md:text-2xl md:mx-0 md:text-porpora "
              data-aos="fade-in"
              data-aos-delay="300"
            >
              Find answers to the most commonly asked questions below.
            </h2>
          </div>
        </div>
      </div>

      <div className="md:container md:mx-auto md:grid md:grid-cols-12">
        <div className="md:col-span-7 md:my-10">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className="relative py-12 mx-4 mt-6 overflow-visible border-b-2 cursor-pointer md:mx-0 border-legno"
              data-aos="fade-up"
              onClick={() => toggleContent(index)}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={() => handleMouseLeave(index)}
            >
              <div className="flex flex-col mx-4 md:mx-0 ">
                <h3 className="text-4xl font-bold font-awesome text-legno">
                  {faq.question}
                </h3>
                <AnimatePresence initial={false}>
                  {faq.isOpen && (
                    <motion.p
                      className="my-4 text-xl italic font-awesome-italic text-porpora"
                      initial="closed"
                      animate="open"
                      exit="closed"
                      variants={contentVariants}
                    >
                      {faq.answer}
                    </motion.p>
                  )}
                </AnimatePresence>
              </div>
              {/* Icon container for hover effect */}
              {faq.isHovered && (
                <motion.div
                  className="absolute transition-opacity opacity-100 cursor-pointer right-4 -bottom-8"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent parent div's onClick from firing
                    toggleContent(index);
                  }}
                  whileHover={{ scale: 1.1 }}
                >
                  {faq.isOpen ? <AccordionCloseIcon /> : <AccordionOpenIcon />}
                </motion.div>
              )}
            </div>
          ))}
        </div>
      </div>

      <Footer />
    </div>
  );
}
