import { useState } from "react";
import config from "../config";

const Newsletter = () => {
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await fetch(`${config.api}/api/newsletter-subscribers`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        },
        body: JSON.stringify({
          data: {
            email: email,
          },
        }),
      });

      if (response.ok) {
        setStatus("Thanks for subscribing!");
        setEmail("");
      } else {
        setStatus("Something went wrong. Please try again.");
      }
    } catch (error) {
      setStatus("Something went wrong. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="relative mx-4 my-16 text-center border-t-2 md:text-start border-legno md:border-0">
        <h2 className="text-5xl text-legno mt-6 mb-2 font-awesome font-bold leading-[66px]">
          NEWSLETTER
        </h2>
        <h3 className="text-2xl italic text-legno font-awesome-italic ">
          Stay Topped!
        </h3>

        {status && <p className="mt-2 text-sm text-porpora">{status}</p>}

        <form onSubmit={handleSubmit}>
          {/* Original mobile layout */}
          <div className="relative flex flex-col gap-2 my-4 md:hidden">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full h-16 text-xl text-center text-opacity-50 border-2 rounded-full font-neuething-medium border-legno"
              placeholder="Your email"
              required
            />
            <button
              type="submit"
              disabled={loading}
              className="h-16 text-xl text-white rounded-full bg-carbone font-neuething-medium disabled:opacity-50"
            >
              {loading ? "SUBSCRIBING..." : "SUBSCRIBE"}
            </button>
          </div>

          {/* New desktop layout */}
          <div className="items-center hidden gap-2 my-4 md:flex">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full h-12 pl-8 text-opacity-50 border-2 rounded-full font-neuething-medium border-legno text-md"
              placeholder="Your email"
              required
            />
            <button
              type="submit"
              disabled={loading}
              className="absolute right-0 h-12 px-4 text-white rounded-full bg-carbone font-neuething-medium text-md disabled:opacity-50"
            >
              {loading ? "SUBSCRIBING..." : "SUBSCRIBE"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Newsletter;
