import React, { useRef, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation } from "swiper/modules";
import "./mystyles.css";
import "swiper/css/navigation";
import PreviousButtonIcon from "./icons/PreviousButtonIcon";
import NextButtonIcon from "./icons/NextButtonIcon";
import config from "../config";

const MenuCarousel = ({ category, menuItems, id }) => {
  const swiperRef = useRef(null);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.swiper.on("slideChange", () => {
        setIsBeginning(swiperRef.current.swiper.isBeginning);
        setIsEnd(swiperRef.current.swiper.isEnd);
      });
    }
  }, []);

  const goPrev = () => {
    if (swiperRef.current && !isBeginning) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const goNext = () => {
    if (swiperRef.current && !isEnd) {
      swiperRef.current.swiper.slideNext();
    }
  };

  return (
    <div className="relative mb-8 overflow-hidden">
      <div className="flex items-center justify-between">
        <h2 className="mx-8 my-6 text-2xl font-bold font-awesome md:text-3xl md:mx-0">
          {category}
        </h2>
        <div>
          <div
            className="hidden gap-2 md:flex"
            data-aos="fade-in"
            data-aos-delay="500">
            <div
              onClick={goPrev}
              style={{
                cursor: isBeginning ? "default" : "pointer",
                userSelect: "none",
              }}>
              <PreviousButtonIcon color="#302713" inactive={isBeginning} />
            </div>
            <div
              onClick={goNext}
              style={{
                cursor: isEnd ? "default" : "pointer",
                userSelect: "none",
              }}>
              <NextButtonIcon color="#302713" inactive={isEnd} />
            </div>
          </div>
        </div>
      </div>
      <Swiper
        ref={swiperRef}
        grabCursor={true}
        modules={[Navigation]}
        slidesPerView={1}
        breakpoints={{
          1440: {
            slidesPerView: 4,
            spaceBetween: 32,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          0: {
            slidesPerView: 1,
            spaceBetween: -50,
          },
        }}
        navigation={{
          prevEl: ".swiper-button-prev",
          nextEl: ".swiper-button-next",
        }}
        class="mySwiperMenu">
        {menuItems.map((menuItem, index) => (
          <SwiperSlide key={index}>
            <a
              href={
                menuItem.attributes.ToastURL ||
                "https://marcsartisanalpizzeria.toast.site/"
              }
              target="_blank"
              className="border-black border-2 mx-8 md:mx-0 h-[350px] md:w-[350px] bg-white flex flex-col w-full group"
              data-aos="fade-in"
              data-aos-delay={index * 200}>
              <div
                className="absolute top-0 bottom-0 left-0 right-0 text-white text-[96px] font-awesome bg-cover bg-center bg-no-repeat pointer-events-none"
                style={{
                  backgroundImage: `url(${config.api}${menuItem.attributes.Image.data?.attributes.url})`,
                }}>
                <div className="absolute top-0 bottom-0 left-0 right-0 bg-black bg-opacity-[35%] flex items-center justify-center">
                  <div className="transition-all duration-300 ease-in-out opacity-0 group-hover:opacity-100">
                    Order
                  </div>
                </div>
              </div>

              <div className="absolute top-0 bottom-0 left-0 right-0 p-6 transition-all duration-300 ease-in-out bg-white backdrop:opacity-100 md:group-hover:opacity-0">
                <div className="flex justify-between mb-2">
                  <h3 className="text-2xl font-bold font-awesome">
                    {menuItem.attributes.Title}
                  </h3>
                  <h4 className="text-2xl italic font-awesome-italic">
                    {menuItem.attributes.Price}
                  </h4>
                </div>
                <h4 className="mb-6 text-sm italic leading-5 font-awesome-italic">
                  {menuItem.attributes.Description}
                </h4>

                <div
                  className="absolute top-0 bottom-0 left-0 right-0 bg-center bg-no-repeat bg-cover"
                  style={{
                    backgroundImage: `url(${config.api}${menuItem.attributes.Image.data?.attributes.url})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    clipPath: "polygon(5% 40%, 95% 40%, 95% 95%, 5% 95%)",
                  }}></div>
              </div>
            </a>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default MenuCarousel;
