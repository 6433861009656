import TomatoesImage from "../images/TomatoesImage.jpg";
import TomatoesHoverImage from "../images/TomatoesHoverImage.jpg";
import FlourImage from "../images/FlourImage.jpg";
import FlourHoverImage from "../images/FlourHoverImage.jpg";
import CheeseImage from "../images/CheeseImage.jpg";
import CheeseHoverImage from "../images/CheeseHoverImage.jpg";
import { useState, useEffect, useRef } from "react";

const isMobileViewport = () => {
  return window.innerWidth <= 768; // Assuming mobile is <= 768px
};

const Ingredients = () => {
  const [tomatoesHovered, setTomatoesHovered] = useState(false);
  const [flourHovered, setFlourHovered] = useState(false);
  const [cheeseHovered, setCheeseHovered] = useState(false);

  const tomatoesRef = useRef(null);
  const flourRef = useRef(null);
  const cheeseRef = useRef(null);

  useEffect(() => {
    if (!isMobileViewport()) return;

    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const observerCallback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (entry.target === tomatoesRef.current) {
            setTomatoesHovered(true);
          } else if (entry.target === flourRef.current) {
            setFlourHovered(true);
          } else if (entry.target === cheeseRef.current) {
            setCheeseHovered(true);
          }
        } else {
          if (entry.target === tomatoesRef.current) {
            setTomatoesHovered(false);
          } else if (entry.target === flourRef.current) {
            setFlourHovered(false);
          } else if (entry.target === cheeseRef.current) {
            setCheeseHovered(false);
          }
        }
      });
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );

    if (tomatoesRef.current) observer.observe(tomatoesRef.current);
    if (flourRef.current) observer.observe(flourRef.current);
    if (cheeseRef.current) observer.observe(cheeseRef.current);

    return () => {
      if (tomatoesRef.current) observer.unobserve(tomatoesRef.current);
      if (flourRef.current) observer.unobserve(flourRef.current);
      if (cheeseRef.current) observer.unobserve(cheeseRef.current);
    };
  }, []);

  const imageContainerStyle = {
    position: "relative",
    display: "inline-block",
  };

  const hoverImageStyleDesktop = {
    position: "absolute",
    top: 0,
    left: 0,
    opacity: 1,
    transition: "opacity 0.3s ease-in-out",
  };

  const hoverImageStyleMobile = {
    ...hoverImageStyleDesktop,
    transition: "opacity 1s ease-in-out 0.5s",
  };

  return (
    <>
      <div className="">
        <div className="md:container flex flex-col mx-8 md:mx-auto md:mt-28 md:grid md:grid-cols-12">
          <h2
            className="text-5xl leading-[4rem] md:text-6xl text-legno md:col-span-9 md:leading-[80px] font-awesome font-bold"
            data-aos="fade-up">
            Local Ingredients, <br />
            Exceptional Flavors
          </h2>
          <p
            className="text-porpora text-xl md:text-2xl my-6 md:col-span-9 font-awesome-italic italic leading-[30px]"
            data-aos="fade-up"
            data-aos-delay="100">
            Marc's Pizzeria is dedicated to supporting Florida suppliers, using
            local farms, ranches, and cheese producers whenever possible. Our
            commitment to quality ensures that every ingredient meets our high
            standards for freshness, organic sourcing, and flavor.
          </p>
        </div>
        <div className="flex mx-4 items-center md:mx-auto flex-col gap-6 md:col-span-12 md:flex-row md:justify-center md:mt-20 md:px-2 md:gap-16">
          <div
            ref={tomatoesRef}
            className="relative"
            onMouseEnter={() => !isMobileViewport() && setTomatoesHovered(true)}
            onMouseLeave={() =>
              !isMobileViewport() && setTomatoesHovered(false)
            }
            style={imageContainerStyle}
            data-aos="fade-up"
            data-aos-delay="200">
            <img
              alt="Fresh tomatoes"
              src={TomatoesImage}
              style={{ width: "400px", height: "auto" }}
            />
            <img
              className="w-[400px]"
              alt="Fresh tomatoes hover"
              src={TomatoesHoverImage}
              style={
                isMobileViewport()
                  ? {
                      ...hoverImageStyleMobile,
                      opacity: tomatoesHovered ? 1 : 0,
                    }
                  : {
                      ...hoverImageStyleDesktop,
                      opacity: tomatoesHovered ? 1 : 0,
                    }
              }
            />
          </div>
          <div
            ref={flourRef}
            className="relative"
            onMouseEnter={() => !isMobileViewport() && setFlourHovered(true)}
            onMouseLeave={() => !isMobileViewport() && setFlourHovered(false)}
            style={imageContainerStyle}
            data-aos="fade-up"
            data-aos-delay="400">
            <img
              alt="Flour"
              src={FlourImage}
              style={{ width: "400px", height: "auto" }}
            />
            <img
              className="w-[400px]"
              alt="Flour hover"
              src={FlourHoverImage}
              style={
                isMobileViewport()
                  ? { ...hoverImageStyleMobile, opacity: flourHovered ? 1 : 0 }
                  : { ...hoverImageStyleDesktop, opacity: flourHovered ? 1 : 0 }
              }
            />
          </div>
          <div
            ref={cheeseRef}
            className="relative"
            onMouseEnter={() => !isMobileViewport() && setCheeseHovered(true)}
            onMouseLeave={() => !isMobileViewport() && setCheeseHovered(false)}
            style={imageContainerStyle}
            data-aos="fade-up"
            data-aos-delay="600">
            <img
              alt="Handmade cheese"
              src={CheeseImage}
              style={{ width: "400px", height: "auto" }}
            />
            <img
              className="w-[400px]"
              alt="Handmade cheese hover"
              src={CheeseHoverImage}
              style={
                isMobileViewport()
                  ? { ...hoverImageStyleMobile, opacity: cheeseHovered ? 1 : 0 }
                  : {
                      ...hoverImageStyleDesktop,
                      opacity: cheeseHovered ? 1 : 0,
                    }
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Ingredients;
