const AccordionCloseIcon = () => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="32" cy="32.0001" r="31.5" fill="#302713" stroke="white" />
      <line
        x1="18"
        y1="31.0001"
        x2="46"
        y2="31.0001"
        stroke="white"
        stroke-width="2"
      />
    </svg>
  );
};
export default AccordionCloseIcon;
