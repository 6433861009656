import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import ArticlesPlaceholderImage from "../images/ArticlesPlaceholderImage.jpg";
import LoadMoreButton from "../components/LoadMoreButton";
import YearPickerButton from "../components/YearPickerButton";
import config from "../config";

const fetchArticles = async () => {
  const reqOptions = {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
    },
  };

  const response = await fetch(
    `${config.api}/api/articles?sort=publishedAt:desc&populate[Image]=*`,
    reqOptions
  );
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  const data = await response.json();
  return data.data; // Extract the array of articles from the response
};

const Article = ({ article }) => {
  const { Title, SubTitle, publishedAt, slug, Image } = article.attributes;

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <Link to={`/articles/${slug}`}>
      <div className="mx-8 my-8 md:container md:grid md:grid-cols-12 md:gap-8 group md:mx-auto ">
        {Image.data ? (
          <img
            src={`${config.api}${Image.data?.[0].attributes.url}`}
            alt={`${config.api}${Image.data?.[0].attributes.name}`}
            className="mb-4  md:col-span-3 md:mb-0"
          />
        ) : (
          <img
            src={ArticlesPlaceholderImage}
            alt="Article"
            className="mb-4  md:col-span-3 md:mb-0"
          />
        )}

        <div className="flex flex-col gap-2 my-auto md:col-span-6 ">
          <span className="text-base opacity-50 font-neuething-regular text-legno">
            {formatDate(publishedAt)}
          </span>
          <h2 className="font-awesome font-bold text-4xl leading-[46px]">
            {Title}
          </h2>
          <h3 className="text-xl italic font-normal font-awesome-italic line-clamp-3">
            {SubTitle}
          </h3>
        </div>
        <div className="my-auto md:col-start-11">
          <button className="relative px-6 py-2 mt-8 transition-all duration-300 ease-in-out border-2 rounded-full border-legno md:col-span-2 font-neuething-medium hover:bg-legno hover:text-white md:h-40 md:w-40 group-hover:border-legno md:group-hover:text-legno md:border-transparent md:text-transparent md:hover:text-white group/button">
            <span className="hidden text-4xl md:inline font-awesome group-hover/button:text-white">
              READ
            </span>
            <span className="md:hidden">READ ARTICLE</span>
          </button>
        </div>
      </div>
    </Link>
  );
};

const ArticleSkeleton = () => {
  return (
    <div className="mx-8 my-8 md:container md:grid md:grid-cols-12 md:gap-8 md:mx-auto animate-pulse">
      <div className="bg-gray-200 h-[200px] md:col-span-3" />
      <div className="flex flex-col gap-2 my-auto md:col-span-6">
        <div className="w-32 h-4 bg-gray-200 rounded" />
        <div className="w-full h-12 bg-gray-200 rounded" />
        <div className="w-full h-20 bg-gray-200 rounded" />
      </div>
      <div className="my-auto md:col-start-11">
        <div className="w-40 h-40 bg-gray-200 rounded-full" />
      </div>
    </div>
  );
};

export default function ArticlesPage() {
  const [articles, setArticles] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getArticles = async () => {
      try {
        const articlesData = await fetchArticles();
        if (Array.isArray(articlesData)) {
          setArticles(articlesData);
        } else {
          setError("Received data is not an array");
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    getArticles();
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="overflow-x-hidden">
      <NavBar showImmediately={true} />
      <div className="md:container md:mx-auto mt-[92px] md:mb-8 mx-4 ">
        <div className="flex flex-col gap-2 py-6 border-t-2 border-b-2 md:flex-row md:justify-between border-legno md:items-center">
          <div className="flex flex-col gap-2">
            <h1
              className="mx-4 text-5xl font-bold md:text-6xl font-awesome md:mx-0"
              data-aos="fade-in"
            >
              ARTICLES
            </h1>
            <h2
              className="mx-4 text-xl italic font-awesome-italic md:mx-0 md:text-porpora "
              data-aos="fade-in"
              data-aos-delay="300"
            >
              Discovering Craftsmanship and Unique Flavors
            </h2>
          </div>
          <div className="hidden md:flex" data-aos="flip-up">
            <YearPickerButton color="black" />
          </div>
        </div>
      </div>

      <div className="px-0 mx-0 md:container md:mx-auto md:px-0">
        {loading ? (
          <>
            <ArticleSkeleton />
            <ArticleSkeleton />
            <ArticleSkeleton />
          </>
        ) : (
          articles.map((article) => (
            <div key={article.id} data-aos="fade-up">
              <Article article={article} />
            </div>
          ))
        )}
      </div>
      <div
        className="container flex justify-center mx-auto my-16"
        data-aos="flip-up"
        data-aos-delay="200"
      >
        <LoadMoreButton />
      </div>

      <Footer />
    </div>
  );
}
