import { Link } from "react-router-dom";

const OrderNowButton = ({ color }) => {
  let buttonClasses =
    "text-white hover:text-legno duration-300 ease-in-out hover:bg-white border-2 px-6 rounded-full text-lg font-neuething-medium leading-[22px]";
  let textClasses = "py-1.5 md:py-2";

  if (color === "black") {
    buttonClasses =
      "text-black hover:text-white hover:bg-legno border-2 px-6 rounded-full text-lg font-neuething-medium border-legno leading-[22px]";
    textClasses = "py-1.5 md:py-2";
  }

  return (
    <button className={buttonClasses}>
      <a
        href="https://marcsartisanalpizzeria.toast.site"
        target="_blank"
        rel="noreferrer">
        <h2 className={textClasses}>ORDER NOW</h2>
      </a>
    </button>
  );
};

export default OrderNowButton;
