import ArticleCards from "./ArticleCards";
import ReadOurBlogButton from "./ReadOurBlogButton";
import OrderNowButton from "./OrderNowButton";
import PizzaMenuIcon from "./icons/PizzaMenuIcon";

const Blog = () => {
  return (
    <>
      <div className="flex md:container md:mx-auto flex-col  my-16 gap-6 md:grid md:grid-cols-12  md:my-32">
        <div className="md:col-span-5 mx-8 md:mx-0" data-aos="fade-right">
          <h2 className="text-5xl leading-[4rem] text-legno mb-6 md:mb-8 font-bold font-awesome md:text-6xl md:leading-[80px]">
            Behind the Dough: Stories from Our Kitchen
          </h2>
          <p
            className="text-xl text-legno md:text-porpora md:mb-8 font-awesome-italic italic lg:text-2xl"
            data-aos="fade-right">
            Visit our blog to get a sneak peek into our kitchen. We peel back
            the layers of our pizza making process, share the secrets of our
            artisanal techniques, and spotlight the local farmers who provide us
            with top tier ingredients
          </p>
          <div className="hidden md:flex" data-aos="fade-right">
            <ReadOurBlogButton />
          </div>
        </div>
        <div
          className="md:col-span-6 md:col-start-7 mx-4 md:mx-0"
          data-aos="fade-left">
          <ArticleCards />
        </div>
        <div className="mx-auto md:hidden" data-aos="fade-up">
          <ReadOurBlogButton />
        </div>
      </div>
    </>
  );
};

export default Blog;
