import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./mystyles.css";

import PizzaMenuIcon from "../images/PizzaImage.jpg";
import config from "../config";

// import required modules
import { Autoplay, Pagination, EffectCards, Navigation } from "swiper/modules";
import { Link } from "react-router-dom";

const fetchArticles = async () => {
  const reqOptions = {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
    },
  };

  const response = await fetch(
    `${config.api}/api/articles?sort=publishedAt:desc&pagination[page]=1&pagination[pageSize]=3&populate[Image]=*`,
    reqOptions
  );
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  const data = await response.json();
  return data.data; // Extract the array of articles from the response
};

const Article = ({ article }) => {
  const { Title, publishedAt, slug, Image } = article.attributes;

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  return (
    <div className="border-black group md:mr-8 border-2 p-6 bg-white flex flex-col mx-4  w-full ">
      <Link to={`/articles/${slug}`}>
        <div className="flex justify-between md:mb-2 mb-4 md:mt-4 font-awesome-italic">
          <span>{formatDate(publishedAt)}</span>
          <span>Read More</span>
        </div>
        <div className="relative w-full flex items-center justify-center group">
          {Image.data ? (
            <img
              src={`${config.api}${Image.data?.[0].attributes.url}`}
              alt={`${config.api}${Image.data?.[0].attributes.name}`}
              className="min-h-[400px]  md:h-[600px] w-full object-cover"
            />
          ) : (
            <img
              src={PizzaMenuIcon}
              alt="Pizza"
              className="min-h-[400px] md:h-[600px] w-full object-cover"
            />
          )}
          {/* Dark overlay */}
          <div className="absolute inset-0 bg-black opacity-20 group-hover:opacity-35 transition-opacity duration-300 ease-in-out pointer-events-none"></div>
          <h2 className="absolute left-0 bottom-10 mx-8 text-[34px] font-awesome  text-white group-hover:text-transparent  transition-all duration-300 ease-in-out font-semibold">
            {Title}
          </h2>
          <span className="absolute font-awesome group-hover:text-white transition-all duration-300 ease-in-out font-semibold text-7xl text-transparent">
            READ MORE!
          </span>
        </div>
      </Link>
    </div>
  );
};

const Cards = () => {
  const [specials, setSpecials] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getArticles = async () => {
      try {
        const specialsData = await fetchArticles();
        if (Array.isArray(specialsData)) {
          setSpecials(specialsData);
        } else {
          setError("Received data is not an array");
        }
      } catch (err) {
        setError(err.message);
      }
    };

    getArticles();
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }
  return (
    <div className="mb-8 ">
      <Swiper
        autoplay={{
          delay: 3000,
          pauseOnMouseEnter: true,
        }}
        loop={true}
        cardsEffect={{
          rotate: 0,
          perSlideOffset: 10,
          slideShadows: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={false}
        effect={""}
        grabCursor={true}
        modules={[Autoplay, Pagination, EffectCards, Navigation]}
        className="mySwiper">
        {specials.map((article) => (
          <div key={article.id} data-aos="fade-up">
            <SwiperSlide>
              <Article article={article} />
            </SwiperSlide>
          </div>
        ))}
      </Swiper>
    </div>
  );
};
export default Cards;
