const DownloadPdfButton = () => {
  return (
    <div className="w-64 text-legno hover:text-white hover:bg-legno transition-all duration-300 ease-in-out border-2 border-legno px-6 rounded-full text-xl">
      <a
        href="/Marcs_Menu.pdf"
        download="Marcs_Menu.pdf"
        className="py-2 text-center block">
        DOWNLOAD PDF
      </a>
    </div>
  );
};

export default DownloadPdfButton;
