import DownloadPdfButton from "../components/DownloadPdfButton";
import Footer from "../components/Footer";
import MenuCarousel from "../components/MenuCarousel";
import NavBar from "../components/NavBar";
import OrderNowButton from "../components/OrderNowButton";
import config from "../config";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

const fetchMenuCategory = async () => {
  const reqOptions = {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
    },
  };

  const response = await fetch(
    `${config.api}/api/menu-categories?populate[menus][populate]=*`,
    reqOptions
  );
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  const data = await response.json();
  return data.data;
};

const MenuCategory = ({ menuCategory }) => {
  const { Category, menus } = menuCategory.attributes;

  return (
    <div className="md:container md:mx-auto md:mt-[64px] md:mb-2 overflow-hidden">
      <MenuCarousel category={Category} menuItems={menus.data} />
    </div>
  );
};

const MenuSkeleton = () => {
  return (
    <div className="container px-4 mx-auto space-y-8 animate-pulse md:space-y-16">
      <div>
        <div className="w-48 h-8 mb-4 bg-gray-200 rounded"></div>
        <div className="h-[400px] bg-gray-200 rounded-lg"></div>
      </div>
      <div>
        <div className="w-48 h-8 mb-4 bg-gray-200 rounded"></div>
        <div className="h-[400px] bg-gray-200 rounded-lg"></div>
      </div>
      <div>
        <div className="w-48 h-8 mb-4 bg-gray-200 rounded"></div>
        <div className="h-[400px] bg-gray-200 rounded-lg"></div>
      </div>
    </div>
  );
};

export default function MenuPage() {
  const [menuCategory, setMenuCategory] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getMenuCategory = async () => {
      try {
        const menuCategoryData = await fetchMenuCategory();
        if (Array.isArray(menuCategoryData)) {
          setMenuCategory(menuCategoryData);
        } else {
          setError("Received data is not an array");
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    getMenuCategory();
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="overflow-hidden">
      <Helmet>
        <title>Menu | Marc's Artisanal Pizzeria Miami Beach</title>
        <meta
          name="description"
          content="Explore our menu of authentic Italian pizzas, appetizers, and specialties. Made with fresh, locally-sourced ingredients at Marc's Artisanal Pizzeria."
        />
        <link rel="canonical" href="https://marcsartisanpizzeria.com/menu" />
      </Helmet>
      <NavBar showImmediately={true} />

      <div className="md:container md:mx-auto mt-[92px] md:mb-8 mx-4">
        <div className="flex flex-col gap-2 py-6 border-t-2 border-b-2 md:flex-row md:justify-between border-legno md:items-center">
          <div className="flex flex-col gap-2">
            <h1
              className="mx-4 text-5xl font-bold md:text-6xl font-awesome md:mx-0"
              data-aos="fade-in"
            >
              OUR MENU
            </h1>
            <h2
              className="mx-4 text-xl italic font-awesome-italic md:text-2xl md:mx-0 md:text-porpora"
              data-aos="fade-in"
              data-aos-delay="300"
            >
              Explore Artisanal Pizzas & Handpicked Delights
            </h2>
          </div>
          <div className="hidden md:flex" data-aos="fade-left">
            <OrderNowButton color="black" />
          </div>
        </div>
      </div>
      <div className="px-0 mx-0 md:container md:mx-auto md:px-0">
        {loading ? (
          <MenuSkeleton />
        ) : (
          menuCategory.map((menuCategory) => (
            <div key={menuCategory.id} data-aos="fade-up">
              <MenuCategory menuCategory={menuCategory} />
            </div>
          ))
        )}
      </div>

      <div className="container flex justify-center w-full mb-16">
        <DownloadPdfButton />
      </div>
      <Footer />
    </div>
  );
}
