import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import HomePage from "./pages/HomePage";
import MenuPage from "./pages/MenuPage";
import NotFoundPage from "./pages/NotFoundPage";
import AboutUsPage from "./pages/AboutUsPage";
import ContactsPage from "./pages/ContactsPage";
import FaqsPage from "./pages/FaqsPage";
import ArticlesPage from "./pages/ArticlesPage";
import LoginPage from "./pages/Login"; // Import your Login component here
import "aos/dist/aos.css";
import AOS from "aos";
import LegalPage from "./pages/LegalPage";
import { ParallaxProvider } from "react-scroll-parallax";
import ArticleDetail from "./pages/ArticleDetail";
import { Helmet } from "react-helmet";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  React.useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const ProtectedRoutes = () => {
  React.useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  return (
    <>
      <ScrollToTop />
      <ParallaxProvider>
        <Helmet>
          <title>Marc's Artisanal Pizzeria | Miami Beach</title>
          <meta
            name="description"
            content="Experience authentic artisanal pizza at Marc's Pizzeria in Miami Beach. Handcrafted with locally sourced ingredients and traditional techniques."
          />
        </Helmet>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/menu" element={<MenuPage />} />
          <Route path="/about" element={<AboutUsPage />} />
          <Route path="/contacts" element={<ContactsPage />} />
          <Route path="/faqs" element={<FaqsPage />} />
          <Route path="/articles" element={<ArticlesPage />} />
          <Route path="/articles/:slug" element={<ArticleDetail />} />
          <Route path="/legal" element={<LegalPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </ParallaxProvider>
    </>
  );
};

export default function App() {
  const [authenticated, setAuthenticated] = useState(true);

  const handleLogin = (password) => {
    // Check if the entered password is correct
    if (password === "inward") {
      // Authentication successful
      setAuthenticated(true);
    } else {
      // Authentication failed
      alert("Incorrect password. Please try again.");
    }
  };

  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      setAuthenticated(true);
    }
  }, []);

  return (
    <BrowserRouter>
      {authenticated ? (
        <ProtectedRoutes />
      ) : (
        <LoginPage onLogin={handleLogin} />
      )}
    </BrowserRouter>
  );
}
