import React, { useEffect, useState } from "react";
import FollowUsButton from "./FollowUsButton";

const InstagramSkeleton = () => {
  return (
    <div className="md:mt-32" data-aos="fade-up">
      <div className="container flex flex-col items-center gap-4 mb-12">
        <div className="w-3/4 h-16 bg-gray-200 rounded animate-pulse"></div>
        <div className="w-1/2 h-16 bg-gray-200 rounded animate-pulse"></div>
      </div>
      <div className="container grid grid-cols-2 gap-2 my-8 md:grid-cols-3 md:gap-8 2xl:gap-x-8 md:my-16">
        {[...Array(6)].map((_, i) => (
          <div
            key={i}
            className="bg-gray-200 rounded aspect-square animate-pulse"
          ></div>
        ))}
      </div>
      <div className="flex justify-center md:mb-32 md:mt-16">
        <div className="w-48 h-12 bg-gray-200 rounded animate-pulse"></div>
      </div>
    </div>
  );
};

const InstagramGrid = () => {
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPhotos = async () => {
      try {
        const accessToken = process.env.REACT_APP_INSTAGRAM_TOKEN;
        const response = await fetch(
          `https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,thumbnail_url,permalink&access_token=${accessToken}`
        );
        const data = await response.json();
        // Limit to the first 6 photos
        setPhotos(data.data.slice(0, 6));
        setLoading(false);
      } catch (error) {
        console.error("Error fetching Instagram photos:", error);
        setLoading(false);
      }
    };

    fetchPhotos();
  }, []);

  if (loading) {
    return <InstagramSkeleton />;
  }

  return (
    <div className="md:mt-32" data-aos="fade-up">
      <h2
        className="text-5xl leading-[4rem] container text-center font-awesome md:text-6xl font-bold md:mb-12 md:leading-[80px]"
        data-aos="fade-up"
      >
        Follow Our Flavorful Journey on <br className="hidden md:flex" />
        Instagram
      </h2>
      <div className="container grid grid-cols-2 gap-2 my-8 md:grid-cols-3 md:gap-8 2xl:gap-x-8 md:my-16">
        {/* Dynamically rendering the first 6 Instagram media items */}
        {photos.map((photo) => (
          <a
            key={photo.id}
            href={photo.permalink}
            target="_blank"
            rel="noopener noreferrer"
            data-aos="fade-up"
            className="aspect-square"
          >
            {photo.media_type === "VIDEO" ? (
              <video
                src={photo.media_url}
                controls={false}
                className="object-cover w-full h-full"
                preload="metadata"
                muted
                autoPlay
              />
            ) : (
              <img
                src={photo.media_url}
                alt={photo.caption || "Instagram photo"}
                className="object-cover w-full h-full"
              />
            )}
          </a>
        ))}
      </div>
      <div className="flex justify-center md:mb-32 md:mt-16" data-aos="fade-up">
        <FollowUsButton />
      </div>
    </div>
  );
};

export default InstagramGrid;
