import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./mystyles.css";

import PizzaMenuIcon from "../images/PizzaImage.jpg";
import config from "../config";

// import required modules
import { Autoplay, Pagination, EffectCards, Navigation } from "swiper/modules";

const fetchSpecial = async () => {
  const reqOptions = {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
    },
  };

  const response = await fetch(
    `${config.api}/api/lunch-specials?populate=*`,
    reqOptions
  );
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  const data = await response.json();
  console.log(data.data);
  return data.data; // Extract the array of articles from the response
};

const Special = ({ special }) => {
  const { Title, Description, Link, Price, Image } = special.attributes;

  return (
    <div className="border-black overflow-hidden h-auto group md:mr-8 border-2 p-6 bg-white flex flex-col mx-4  w-full ">
      <a href={Link} target="_blank">
        <div className="md:flex md:justify-between md:mb-8">
          <div className="">
            <h2 className="text-4xl font-awesome-italic italic text-legno">
              {Title}
            </h2>
            <h3 className="text-xl font-awesome-italic italic text-legno line-clamp-2">
              {Description}
            </h3>
          </div>
          <h4 className="text-4xl mb-6 font-awesome-italic italic text-legno">
            {Price}
          </h4>
        </div>
        <div className="relative w-full flex items-center justify-center group">
          {Image.data ? (
            <img
              src={`${config.api}${Image.data?.attributes.url}`}
              alt={`${config.api}${Image.data?.attributes.name}`}
              className="md:h-[328px] w-full md:object-cover"
            />
          ) : (
            <img
              src={PizzaMenuIcon}
              alt="Pizza"
              className="md:h-[328px] w-full md:object-cover"
            />
          )}
          {/* Dark overlay */}
          <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-35 transition-opacity duration-300 ease-in-out pointer-events-none"></div>

          <span className="absolute font-awesome group-hover:text-white transition-all duration-300 ease-in-out font-semibold text-8xl text-transparent text-center">
            ORDER NOW!
          </span>
        </div>
      </a>
    </div>
  );
};

const Cards = () => {
  const [specials, setSpecials] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getSpecials = async () => {
      try {
        const specialsData = await fetchSpecial();
        if (Array.isArray(specialsData)) {
          setSpecials(specialsData);
        } else {
          setError("Received data is not an array");
        }
      } catch (err) {
        setError(err.message);
      }
    };

    getSpecials();
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }
  return (
    <div className="mb-8 ">
      <Swiper
        autoplay={{
          delay: 3000,
          pauseOnMouseEnter: true,
        }}
        loop={true}
        cardsEffect={{
          rotate: 0,
          perSlideOffset: 10,
          slideShadows: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={false}
        effect={""}
        grabCursor={true}
        modules={[Autoplay, Pagination, EffectCards, Navigation]}
        className="mySwiper">
        {specials.map((special) => (
          <div key={special.id} data-aos="fade-up">
            <SwiperSlide>
              <Special special={special} />
            </SwiperSlide>
          </div>
        ))}
      </Swiper>
    </div>
  );
};
export default Cards;
