import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import config from "../config";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";

import ArticlesPlaceholderImage from "../images/ArticlesPlaceholderImage.jpg";

const fetchArticle = async (slug) => {
  const reqOptions = {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
    },
  };

  const response = await fetch(
    `${config.api}/api/articles?filters[slug][$eq]=${slug}&populate[Image]=*`,
    reqOptions
  );
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  const data = await response.json();
  return data.data[0]; // Assuming the response is an array with one article
};

const ArticleSkeleton = () => {
  return (
    <>
      <div className="md:container md:mx-auto mt-[92px] md:mb-8 mx-4">
        <div className="flex flex-col gap-2 py-6 border-t-2 border-b-2 md:flex-row md:justify-between border-legno md:items-center">
          <div className="flex flex-col gap-2">
            <div className="w-32 h-8 bg-gray-200 rounded animate-pulse"></div>
          </div>
        </div>
      </div>

      <div className="px-4 mx-auto md:container">
        <div className="relative gap-2 my-auto md:grid md:grid-cols-2 md:gap-9 md:pb-32">
          <div className="flex flex-col gap-4 my-auto animate-pulse">
            <div className="w-32 h-4 bg-gray-200 rounded"></div>
            <div className="w-full h-12 bg-gray-200 rounded"></div>
            <div className="w-3/4 h-8 bg-gray-200 rounded"></div>
          </div>
          <div className="w-full h-[400px] bg-gray-200 rounded animate-pulse"></div>
        </div>
        <div className="md:grid md:grid-cols-12 md:mb-24">
          <div className="space-y-4 md:col-span-8 md:col-start-3 animate-pulse">
            <div className="w-full h-4 bg-gray-200 rounded"></div>
            <div className="w-full h-4 bg-gray-200 rounded"></div>
            <div className="w-3/4 h-4 bg-gray-200 rounded"></div>
          </div>
        </div>
      </div>
    </>
  );
};

const ArticleDetail = () => {
  const { slug } = useParams();
  const [article, setArticle] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getArticle = async () => {
      try {
        const articleData = await fetchArticle(slug);
        setArticle(articleData);
      } catch (err) {
        setError(err.message);
      }
    };

    getArticle();
  }, [slug]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!article) {
    return (
      <>
        <NavBar showImmediately={true} />
        <ArticleSkeleton />
        <Footer />
      </>
    );
  }

  const { Title, SubTitle, Content, publishedAt, Image } = article.attributes;

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <>
      <Helmet>
        <title>{`${Title} | Marc's Artisanal Pizzeria Blog`}</title>
        <meta name="description" content={SubTitle} />
        <link
          rel="canonical"
          href={`https://marcsartisanpizzeria.com/articles/${slug}`}
        />

        {/* Social sharing meta tags */}
        <meta property="og:title" content={Title} />
        <meta property="og:description" content={SubTitle} />
        <meta property="og:type" content="article" />
        <meta property="article:published_time" content={publishedAt} />
      </Helmet>
      <NavBar showImmediately={true} />

      <div className="md:container md:mx-auto mt-[92px] md:mb-8 mx-4 ">
        <div className="flex flex-col gap-2 py-6 border-t-2 border-b-2 md:flex-row md:justify-between border-legno md:items-center">
          <div className="flex flex-col gap-2 text-2xl font-bold font-awesome">
            ARTICLES
          </div>
          <div className="hidden md:flex" data-aos="flip-up"></div>
        </div>
      </div>

      <div className="px-4 mx-auto md:container">
        <div className="relative gap-2 my-auto md:grid md:grid-cols-2 md:gap-9 md:pb-32">
          {/* Border element */}
          <div className="absolute -bottom-2 left-0 w-[15%] border-b-2 b border-l-2 h-48 border-black"></div>
          <div className="flex flex-col gap-4 my-auto">
            <span className="text-base opacity-50 font-neuething-regular text-legno">
              {formatDate(publishedAt)}
            </span>
            <h1 className="font-awesome font-bold text-4xl md:leading-[50px]">
              {Title}
            </h1>
            <h3 className="text-xl italic font-normal font-awesome-italic text-porpora">
              {SubTitle}
            </h3>
          </div>
          <div className="w-full h-full ">
            {Image.data ? (
              <img
                src={`${config.api}${Image.data?.[0].attributes.url}`}
                alt={`${config.api}${Image.data?.[0].attributes.name}`}
                className="w-full  md:object-cover"
              />
            ) : (
              <img
                src={ArticlesPlaceholderImage}
                alt="Article Placeholder"
                className="w-full"
              />
            )}
          </div>
        </div>
        <div className="md:grid md:grid-cols-12 md:mb-24">
          <div className="md:col-span-8 md:col-start-3">
            {Content.map((block, index) => (
              <p key={index} className="mb-4">
                {block.children.map((child, i) => (
                  <span key={i} className={child.bold ? "font-bold" : ""}>
                    {child.text}
                  </span>
                ))}
              </p>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ArticleDetail;
